import { Environment } from './types';

export const environment: Environment = {
  production: false,
  apiURL:
    'https://ai-home-health-web-api.carepoint-stage-app.prj.gcp.wellsky.io/api/v1',
  authentication: {
    clientId: 'ci-ui-ai-stage',
    authServerId: 'aussyy6p7tqLFjziZ1d6',
    oktaDomain: 'https://wellsky-ciam.oktapreview.com',
    redirectUri: '/login/callback',
    scopes: [
      'openid',
      'profile',
      'email',
      'offline_access',
      'api.wellsky.ai.ci.roles.stage',
      'api.wellsky.ai.vbi.roles.stage',
    ],
  },
  ciRoleClaimName: 'ai.ci.roles.stage',
  vbiRoleClaimName: 'ai.vbi.roles.stage',
};
